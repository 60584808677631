import React, {useState} from 'react';
import {AiFillCaretDown, AiFillCaretRight, AiFillMinusCircle} from 'react-icons/ai';

export const PlayerUnitOptions = ({ propUnitOptions, actionRemoveUnit, propCardName, propUnitIndex }) => {
  // Load in props

  /*
    Sample Structure

                "unit_id" : 1,
                "unit_name" : "Trooper",
                "unit_stats" : 
                  {
                    "mv" : 4,
                    "mw": 14,            
                    "cc" : 12,
                    "st" : 6,
                    "def" : 0,
                    "ar" : 22,
                    "w" : 2,
                    "pw": 12,
                    "ld": 12,
                    "base" : "30mm",
                    "dp" : 4,
                    "sp" : 0
                  }
                ,
                "unit_abilities" : [
                  {
                    "ability_id" : 1,
                    "ability_name" : "Camouflage (1)",
                    "ability_desc" : "Lorem ipsum"
                  },
                  {
                    "ability_id" : 2,
                    "ability_name" : "Determination",
                    "ability_desc" : "Lorem ipsum"
                  }
                ],
                "unit_equipment" : [
                  {
                    "equip_id" : 1,
                    "equip_name" : "AG-17 Assault Rifle",
                    "equip_stats" : 
                      {
                        "equip_cc" : "-",
                        "equip_short_1" : "Up to 12\"",
                        "equip_short_2" : "+0/12",
                        "equip_long_1" : "12\" - 36\"",
                        "equip_long_2" : "-1/12",
                        "equip_crit_fail" : "20"
                      }
                    ,
                    "equip_abilities" : [
                      {
                        "ability_id" : 1,
                        "ability_name" : "Sample",
                        "ability_desc" : "Lorem Ipsum"
                      }
                    ]

  */

  //const [equipSelect, setEquipSelect] = useState(0);
  //let equipSelect = 0;

  const [isMinimize, setMinimize] = useState(true);
  function handleMinimize() {
    setMinimize(!isMinimize);
  }

  function handlePlayerEquipSelect(argSelect) {
    //setEquipSelect(argSelect)
    propUnitOptions["equip_selected"] = argSelect;
    //console.log(equipSelect)
  }

  let abilities = [];
  propUnitOptions["unit_abilities"].forEach((item,index)=>{
    let abilityName = ""; 
    if(item["ability_mod"])
    {
      abilityName = item["ability_name"].replace("(X)", "("+item["ability_mod"]+")");
    } else {
      abilityName = item["ability_name"];
    }
    if(index == propUnitOptions["unit_abilities"].length-1)
    {
      abilities.push(<span className='pr-2' title={item["ability_desc"]}>{abilityName}</span>)
    } else {
      abilities.push(<span className='pr-2' title={item["ability_desc"]}>{abilityName},</span>)
    }
  });

  
  let equipment = [];
  propUnitOptions["unit_equipment"].forEach((item,index)=>{
    let equip_abilities = [];
    if(item["equip_abilities"].length > 0)
    {
      item["equip_abilities"].forEach((abilityItem, abilityIndex)=> {
        let equipAbilityName = "";
        if(abilityItem["ability_mod"])
        {
          equipAbilityName = abilityItem["ability_name"].replace("(X)", "("+abilityItem["ability_mod"]+")");
        } else {
          equipAbilityName = abilityItem["ability_name"];
        }
        equip_abilities.push(<div title={abilityItem["ability_desc"]}>{equipAbilityName}</div>)
      })
    }

    //if (item["equip_group"] != 0 && propUnitOptions["equip_selected"] ==item["equip_name"]) {
    if (item["equip_group"] != 0) {
      //if(equipSelect == 0)
      if(propUnitOptions["equip_selected"] ==item["equip_name"]) {
      let equip_options = [];
      equip_options.push(<input type="radio" value={item["equip_name"]} name={"player_group_"+propUnitIndex+"_"+item["equip_group"]} onClick={()=>handlePlayerEquipSelect(item["equip_name"])} defaultChecked={propUnitOptions["equip_selected"] ==item["equip_name"]} />);
      equip_options.push(<label> {item["equip_name"]}</label>);
      
      equipment.push(<tr className= {index%2? 'bg-slate-300' : 'bg-slate-300'}>
        <td>{equip_options}</td>
        <td className=' border-white border-r-2'>{item["equip_stats"]["equip_cc"]}</td>
        <td className=' border-white border-r-2'><div>{item["equip_stats"]["equip_short_1"]}</div><div>{item["equip_stats"]["equip_short_2"]}</div></td>
        <td className=' border-white border-r-2'><div>{item["equip_stats"]["equip_long_1"]}</div><div>{item["equip_stats"]["equip_long_2"]}</div></td>
        <td className=' border-white border-r-2'>{item["equip_stats"]["equip_crit_fail"]}</td>
        <td>{equip_abilities}</td>
        </tr>);
      }
    } else {
      equipment.push(<tr className= {index%2? 'bg-slate-50' : 'bg-slate-100'}>
        <td>{item["equip_name"]}</td>
        <td className=' border-white border-r-2'>{item["equip_stats"]["equip_cc"]}</td>
        <td className=' border-white border-r-2'><div>{item["equip_stats"]["equip_short_1"]}</div><div>{item["equip_stats"]["equip_short_2"]}</div></td>
        <td className=' border-white border-r-2'><div>{item["equip_stats"]["equip_long_1"]}</div><div>{item["equip_stats"]["equip_long_2"]}</div></td>
        <td className=' border-white border-r-2'>{item["equip_stats"]["equip_crit_fail"]}</td>
        <td>{equip_abilities}</td>
        </tr>)
    }
  });

  if(isMinimize)
  {
    return (
      <div className='mb-4 border-b-2 border-[#003]'>
            <table className='w-[100%] mb-2'>
              <tr>
                <td className='pl-2 ' onClick={()=>handleMinimize()}><h3 className=' text-base' ><AiFillCaretRight className='inline' />{propUnitOptions["unit_name"]} {propUnitOptions["unit_required"] ? '('+propUnitOptions["unit_required"]["unit_count"]+':'+propUnitOptions["unit_required"]["unit_name"]+')' : ''}</h3></td>
                <td className='text-right w-[32%]'>
                  <span className='px-2 font-semibold'>DP: {propUnitOptions["unit_stats"]["dp"]}</span>
                  <span className='px-2 font-semibold'>SP: {propUnitOptions["unit_stats"]["sp"]}</span>
                </td>
                <td className='w-[10%]'><button className='bg-[#003] hover:bg-sky-700 text-white px-3 py-1 text-sm leading-5 rounded-full'
                        onClick={() => actionRemoveUnit(propCardName, propUnitIndex)}
                      ><AiFillMinusCircle /></button></td>
              </tr>
              </table>
      </div>
    )
  } else {
  return (
    <div className='mb-4 border-b-2 border-[#003]'>
      <table className='w-[100%] mb-2'>
        <tr>
          <td className='pl-2 ' onClick={()=>handleMinimize()}><h3 className=' text-base'><AiFillCaretDown className='inline' />{propUnitOptions["unit_name"]} {propUnitOptions["unit_required"] ? '('+propUnitOptions["unit_required"]["unit_count"]+':'+propUnitOptions["unit_required"]["unit_name"]+')' : ''}</h3></td>
          <td className='text-right w-[32%]'>
            <span className='px-2 font-semibold'>DP: {propUnitOptions["unit_stats"]["dp"]}</span>
            <span className='px-2 font-semibold'>SP: {propUnitOptions["unit_stats"]["sp"]}</span>
          </td>
          <td className='w-[10%]'><button className='bg-[#003] hover:bg-sky-700 text-white px-3 py-1 text-sm leading-5 rounded-full'
                  onClick={() => actionRemoveUnit(propCardName, propUnitIndex)}
                ><AiFillMinusCircle /></button></td>
        </tr>
      </table>
      
        <table className='w-[100%] text-center border-white border-b-4'>
            <tr className='bg-white'>
                <th>MV</th>
                <th>MW</th>
                <th>CC</th>
                <th>ST</th>
                <th>DEF</th>
                <th>AR</th>
                <th>W</th>
                <th>PW</th>
                <th>LD</th>
                <th>Base</th>
            </tr>
            <tr className='bg-slate-50'>
                <td>{propUnitOptions["unit_stats"]["mv"]}</td>
                <td>{propUnitOptions["unit_stats"]["mw"]}</td>
                <td>{propUnitOptions["unit_stats"]["cc"]}</td>
                <td>{propUnitOptions["unit_stats"]["st"]}</td>
                <td>{propUnitOptions["unit_stats"]["def"]}</td>
                <td>{propUnitOptions["unit_stats"]["ar"]}</td>
                <td>{propUnitOptions["unit_stats"]["w"]}</td>
                <td>{propUnitOptions["unit_stats"]["pw"]}</td>
                <td>{propUnitOptions["unit_stats"]["ld"]}</td>
                <td>{propUnitOptions["unit_stats"]["base"]}</td>
            </tr>
        </table>
        <div className='py-2 bg-slate-100'>
          <span className='font-bold mx-2'>Abilities:</span>
          {abilities}
        </div>
        <table className='bg-white w-[100%] text-center'>
            <tr>
                <th className='p-2'>Equipment</th>
                <th className='p-2'>CC</th>
                <th className='p-2'>Short</th>
                <th className='p-2'>Long</th>
                <th className='p-2'>Crit Fail</th>
                <th className='p-2'>Abilities</th>
            </tr>
            {equipment}
        </table>
        <div></div>
    </div>
  )
  }
}
