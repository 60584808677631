import React, {useState} from 'react';
import { UnitProfileOption } from './UnitProfileOption';
import {AiFillCaretDown, AiFillCaretRight, AiFillPlusCircle} from 'react-icons/ai';

export const UnitProfileCard = ({ propUnitStats, actionAddUnit }) => {

  // Load in Unit Stats
  // Load in Varient Stats
  // Load in Unit options with ability to add to Player List
  // Load in Equipment Stats

  // Load in props
  let cardName = propUnitStats["card_name"];
  let cardUnits = propUnitStats["card_units"]; // Array of units

  const [hideCard, setHideCard] = useState(false);

    const handleCard = () => {
        setHideCard(!hideCard);
    }

  let outputUnits = [];
  cardUnits.forEach((item,index)=>{
    outputUnits.push( <UnitProfileOption propUnitOptions={item} actionAddUnit={actionAddUnit} propUnitCardName={cardName} />)
  })
    
  return (
    <div className='w-[100%]'>
        <div className='flex flex-row border-yellow-500 border-double border-y-4 border-x-8 p-2 bg-[#003]' onClick={handleCard}>
          <div className=' text-white'>{hideCard ? <AiFillCaretDown size={20} /> : <AiFillCaretRight size={20} /> }</div>
          <h3 className='basis-1/2 text-white pl-4 text-base'>{cardName}</h3>
          
        </div>

    <div className={hideCard ? 'block py-3 px-1' : 'hidden'}>
        {outputUnits}
        </div>
    </div>
  )
}
