import React from 'react';
//import iconSample from '../assets/icon-capitol.png'


export const FactionButton = ({propID, propName, propIcon, actionSelectFaction}) => {

    let iconPath = `/assets/${propIcon}.png`;
  return (
    <div className='m-4'>
        <img className='factionSelectIcon w-[100px]' src={iconPath} alt={propName} onClick={() => actionSelectFaction(propID, propName)} />
    </div>
  )
}
