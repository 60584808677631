import React from 'react'

export const PrintUnitOptions = ({ propUnitOptions, propCardName, propUnitIndex }) => {
    function handlePlayerEquipSelect(argSelect) {
        //setEquipSelect(argSelect)
        propUnitOptions["equip_selected"] = argSelect;
        //console.log(equipSelect)
      }
    
      let abilities = [];
      propUnitOptions["unit_abilities"].forEach((item,index)=>{
        let abilityName = ""; 
        if(item["ability_mod"])
        {
          abilityName = item["ability_name"].replace("(X)", "("+item["ability_mod"]+")");
        } else {
          abilityName = item["ability_name"];
        }
        if(index == propUnitOptions["unit_abilities"].length-1)
        {
          abilities.push(<span className='pr-2'>{abilityName}</span>)
        } else {
          abilities.push(<span className='pr-2'>{abilityName},</span>)
        }
      });
    
      
      let equipment = [];
      propUnitOptions["unit_equipment"].forEach((item,index)=>{
        let equip_abilities = [];
        if(item["equip_abilities"].length > 0)
        {
          item["equip_abilities"].forEach((abilityItem, abilityIndex)=> {
            let equipAbilityName = "";
            if(abilityItem["ability_mod"])
            {
              equipAbilityName = abilityItem["ability_name"].replace("(X)", "("+abilityItem["ability_mod"]+")");
            } else {
              equipAbilityName = abilityItem["ability_name"];
            }
            equip_abilities.push(<div>{equipAbilityName}</div>)
          })
        }
    
        
        if (item["equip_group"] != 0) {
          //if(equipSelect == 0)
          if(propUnitOptions["equip_selected"] ==item["equip_name"]) {
          let equip_options = [];
          equip_options.push(<input type="radio" value={item["equip_name"]} name={"print_group_"+propUnitIndex+"_"+item["equip_group"]} onClick={()=>handlePlayerEquipSelect(item["equip_name"])} defaultChecked={propUnitOptions["equip_selected"] ==item["equip_name"]} />);
          equip_options.push(<label> {item["equip_name"]}</label>);
          
          equipment.push(<tr className={index%2? 'bg-slate-300 border-black border-t-2' : 'bg-slate-300 border-black border-t-2'}>
            <td className=' border-black border-r-2'>{equip_options}</td>
            <td className=' border-black border-r-2'>{item["equip_stats"]["equip_cc"]}</td>
            <td className=' border-black border-r-2'><div>{item["equip_stats"]["equip_short_1"]}</div><div>{item["equip_stats"]["equip_short_2"]}</div></td>
            <td className=' border-black border-r-2'><div>{item["equip_stats"]["equip_long_1"]}</div><div>{item["equip_stats"]["equip_long_2"]}</div></td>
            <td className=' border-black border-r-2'>{item["equip_stats"]["equip_crit_fail"]}</td>
            <td>{equip_abilities}</td>
            </tr>);
          }
        } else {
          equipment.push(<tr className={index%2? 'bg-slate-50 border-black border-t-2' : 'bg-slate-100 border-black border-t-2'}>
            <td className=' border-black border-r-2'>{item["equip_name"]}</td>
            <td className=' border-black border-r-2'>{item["equip_stats"]["equip_cc"]}</td>
            <td className=' border-black border-r-2'><div>{item["equip_stats"]["equip_short_1"]}</div><div>{item["equip_stats"]["equip_short_2"]}</div></td>
            <td className=' border-black border-r-2'><div>{item["equip_stats"]["equip_long_1"]}</div><div>{item["equip_stats"]["equip_long_2"]}</div></td>
            <td className=' border-black border-r-2'>{item["equip_stats"]["equip_crit_fail"]}</td>
            <td>{equip_abilities}</td>
            </tr>)
        }
      });
    
      return (
        <div className='mb-4 border-2 border-[#000] break-inside-avoid'>
          <table className='w-[100%] mb-2'>
            <tr>
              <td className='pl-2 '><h3 className=' text-base'>{propUnitOptions["unit_name"]} {propUnitOptions["unit_required"] ? '('+propUnitOptions["unit_required"]["unit_count"]+':'+propUnitOptions["unit_required"]["unit_name"]+')' : ''}</h3></td>
              <td className='text-right w-[32%]'>
                <span className='px-2 font-semibold'>DP: {propUnitOptions["unit_stats"]["dp"]}</span>
                <span className='px-2 font-semibold'>SP: {propUnitOptions["unit_stats"]["sp"]}</span>
              </td>
            </tr>
          </table>
          
            <table className='w-[100%] text-center border-black border-b-1'>
                <tr className='bg-white border-black border-t-0'>
                    <th className='border-black border-r-0'>MV</th>
                    <th className='border-black border-r-0'>MW</th>
                    <th className='border-black border-r-0'>CC</th>
                    <th className='border-black border-r-0'>ST</th>
                    <th className='border-black border-r-0'>DEF</th>
                    <th className='border-black border-r-0'>AR</th>
                    <th className='border-black border-r-0'>W</th>
                    <th className='border-black border-r-0'>PW</th>
                    <th className='border-black border-r-0'>LD</th>
                    <th className=''>Base</th>
                </tr>
                <tr className='bg-slate-50 border-black border-y-2'>
                    <td className='border-black border-r-2'>{propUnitOptions["unit_stats"]["mv"]}</td>
                    <td className='border-black border-r-2'>{propUnitOptions["unit_stats"]["mw"]}</td>
                    <td className='border-black border-r-2'>{propUnitOptions["unit_stats"]["cc"]}</td>
                    <td className='border-black border-r-2'>{propUnitOptions["unit_stats"]["st"]}</td>
                    <td className='border-black border-r-2'>{propUnitOptions["unit_stats"]["def"]}</td>
                    <td className='border-black border-r-2'>{propUnitOptions["unit_stats"]["ar"]}</td>
                    <td className='border-black border-r-2'>{propUnitOptions["unit_stats"]["w"]}</td>
                    <td className='border-black border-r-2'>{propUnitOptions["unit_stats"]["pw"]}</td>
                    <td className='border-black border-r-2'>{propUnitOptions["unit_stats"]["ld"]}</td>
                    <td className=''>{propUnitOptions["unit_stats"]["base"]}</td>
                </tr>
            </table>
            <div className='py-2 bg-slate-100'>
              <span className='font-bold mx-2'>Abilities:</span>
              {abilities}
            </div>
            <table className='bg-white w-[100%] text-center'>
                <tr>
                    <th className='p-2'>Equipment</th>
                    <th className='p-2'>CC</th>
                    <th className='p-2'>Short</th>
                    <th className='p-2'>Long</th>
                    <th className='p-2'>Crit Fail</th>
                    <th className='p-2'>Abilities</th>
                </tr>
                {equipment}
            </table>
            <div></div>
        </div>
      )
}
