import React from 'react'
import { PrintUnitCard } from './PrintUnitCard';

export const PrintListContainer = ({ propUnitList }) => {

    let playerUnitList = []

    // Setup for multiple 
    propUnitList.forEach((item,index)=>{
      playerUnitList.push( <PrintUnitCard propUnitStats={item}/>)
    });
  
    return (
      <div id='printListContainer' className='hidden print:block print:w-[100%]'>
        <div className="list-container m-4 min-h-[300px]">
          {playerUnitList} 
        </div>
      </div>
    )
}
