//import logo from './logo.svg';
import React, {useState, useEffect} from 'react';
import {AiFillPlusCircle} from 'react-icons/ai';
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import jsPDF from 'jspdf';

// Components
import { Navbar } from './components/Navbar';
import { ListContainer } from './components/ListContainer';
import { FactionListContainer } from './components/FactionListContainer';
import { PlayerListContainer } from './components/PlayerListContainer';
import { UnitProfileCard } from './components/UnitProfileCard';
import { PlayerUnitCard } from './components/PlayerUnitCard'
import { FactionButton } from './components/FactionButton';
import ValidationBar from './components/ValidationBar';
import { PrintListContainer } from './components/PrintListContainer';

//import './App.css';

function App() {

  const playerUnitList = [];
  const [factionList, setFactionList] = useState([]);
  const [playerUnitListState, setPlayerUnitList] = useState([]);
  const [factionUnitListState, setFactionUnitList] = useState([]);
  const [guiState, setGuiState] = useState("faction"); // faction
  const [factionModal, setFactionModal] = useState(false);
  const [selectedFaction, setSelectedFaction] = useState("");

  // Point Validation
  const [pointsTotal, setPointsTotal] = useState(40);
  const [pointsCalc, setPointsCalc] = useState(0);
  const [spCalc, setSPCalc] = useState(0);
  const [listErrors, setListErrors] = useState([]); // Collection of errors detected by validation
  const [listValid, setListValid] = useState(false);

  // Init Event
  useEffect(() => {
    //ParseUnitData();
  }, []);

  function SelectFaction (argID, argFaction) {
    setGuiState("load");
    // Load Unit Data
    setSelectedFaction(argFaction)
    //setFactionUnitList(LoadUnitData(argID))
    LoadUnitData(argID)
    //setFactionUnitList(ParseUnitData());
    //setGuiState("build");
  }

  function LoadFactionData() {
    fetch("https://warzone.resnovagames.com/api/get_factions.php", {
      method: "GET"
    }).then((response) => response.json())
    .then((data) => {
      setFactionList(data);
    })
  }

  async function LoadUnitData(argFaction) {
    // Call to DB to load in data on unit profiles
    await fetch("https://warzone.resnovagames.com/api/get_units.php?f="+argFaction, {
      method: "GET"
    }).then((response) => response.json())
    .then((data) => {
      console.log("Faction Units Loaded");
      setFactionUnitList(data)
    }).then(setGuiState("build"))
    .catch(error => console.error(error));

    //return ParseUnitData();
  }

  function ParseUnitData() {
    // Take the loaded Unit Data and put into JSON structure

    let sampleJson = [
      {
         "card_id": 1,
         "card_name": "Venusian Rangers",
         "card_units": [
           {
             "unit_id": 1,
             "unit_name": "Trooper",
             "unit_stats": {
               "mv": "4",
               "mw": "14",
               "cc": "12",
               "st": "6",
               "def": "0",
               "ar": "22",
               "w": "2",
               "pw": "12",
               "ld": "12",
               "base": "30mm",
               "dp": 4,
               "sp": 0
             },
             "unit_abilities": [
               {
                 "ability_id": 7,
                 "ability_name": "Camouflage (X)",
                 "ability_desc": "Any Obstruction modifiers to the TN for a Shoot Action Test targeting this Unit are increased by +X.",
                 "ability_mod": "1"
               },
               {
                 "ability_id": 11,
                 "ability_name": "Determination",
                 "ability_desc": "This Unit may re-roll any TN (PW) or TN (LD) Tests Failures.",
                 "ability_mod": ""
               }
             ],
             "unit_equipment": [
               {
                 "equip_id": 6,
                 "equip_name": "AG-17 Assault Rifle",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "-",
                   "equip_short_1": "Up to 12\"",
                   "equip_short_2": "+0/12",
                   "equip_long_1": "12\"-36\"",
                   "equip_long_2": "-1/1",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": []
               },
               {
                 "equip_id": 2,
                 "equip_name": "MP-105 Handgun",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "+0/12",
                   "equip_short_1": "Up to 6\"",
                   "equip_short_2": "+1/12",
                   "equip_long_1": "6\"-18\"",
                   "equip_long_2": "-1/12",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": []
               }
             ]
           },
           {
             "unit_id": 2,
             "unit_name": "Specialist - Medic",
             "unit_stats": {
               "mv": "4",
               "mw": "14",
               "cc": "12",
               "st": "6",
               "def": "0",
               "ar": "22",
               "w": "2",
               "pw": "12",
               "ld": "12",
               "base": "30mm",
               "dp": 4,
               "sp": 0
             },
             "unit_abilities": [
               {
                 "ability_id": 7,
                 "ability_name": "Camouflage (X)",
                 "ability_desc": "Any Obstruction modifiers to the TN for a Shoot Action Test targeting this Unit are increased by +X.",
                 "ability_mod": "1"
               },
               {
                 "ability_id": 11,
                 "ability_name": "Determination",
                 "ability_desc": "This Unit may re-roll any TN (PW) or TN (LD) Tests Failures.",
                 "ability_mod": ""
               },
               {
                 "ability_id": 23,
                 "ability_name": "First Aid (X)",
                 "ability_desc": "Once during this Unit?s Activation, immediately before or after declaring or performing an Action, this Unit, if in base-to-base contact with a Friendly Unit (including itself) with one or more Wound Counters, may attempt a TN(X) Test. Success removes a Wound Counter and a natural roll of a ?1? removes all Wound Counters. Friendly Units (not including itself) within 6\" of this Unit are never assigned more than one Wound Counter as a result of a failed Armor Test.",
                 "ability_mod": "12"
               }
             ],
             "unit_equipment": [
               {
                 "equip_id": 6,
                 "equip_name": "AG-17 Assault Rifle",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "-",
                   "equip_short_1": "Up to 12\"",
                   "equip_short_2": "+0/12",
                   "equip_long_1": "12\"-36\"",
                   "equip_long_2": "-1/1",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": []
               },
               {
                 "equip_id": 2,
                 "equip_name": "MP-105 Handgun",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "+0/12",
                   "equip_short_1": "Up to 6\"",
                   "equip_short_2": "+1/12",
                   "equip_long_1": "6\"-18\"",
                   "equip_long_2": "-1/12",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": []
               }
             ],
             "unit_required": {
               "unit_name": "Venusian Rangers",
               "unit_count": "1"
             }
           },
           {
             "unit_id": 3,
             "unit_name": "Support",
             "unit_stats": {
               "mv": "4",
               "mw": "14",
               "cc": "12",
               "st": "6",
               "def": "0",
               "ar": "22",
               "w": "2",
               "pw": "12",
               "ld": "12",
               "base": "30mm",
               "dp": 4,
               "sp": -3
             },
             "unit_abilities": [
               {
                 "ability_id": 7,
                 "ability_name": "Camouflage (X)",
                 "ability_desc": "Any Obstruction modifiers to the TN for a Shoot Action Test targeting this Unit are increased by +X.",
                 "ability_mod": "1"
               },
               {
                 "ability_id": 11,
                 "ability_name": "Determination",
                 "ability_desc": "This Unit may re-roll any TN (PW) or TN (LD) Tests Failures.",
                 "ability_mod": ""
               }
             ],
             "unit_equipment": [
               {
                 "equip_id": 8,
                 "equip_name": "MG-80 HMG",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "-",
                   "equip_short_1": "Up to 24\"",
                   "equip_short_2": "+0/14",
                   "equip_long_1": "24\"-48\"",
                   "equip_long_2": "-2/13",
                   "equip_crit_fail": "19"
                 },
                 "equip_abilities": [
                   {
                     "ability_id": 2,
                     "ability_name": "Burst (X)",
                     "ability_desc": "",
                     "ability_mod": "3"
                   },
                   {
                     "ability_id": 9,
                     "ability_name": "Recoil (X)",
                     "ability_desc": "",
                     "ability_mod": "4"
                   }
                 ]
               },
               {
                 "equip_id": 2,
                 "equip_name": "MP-105 Handgun",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "+0/12",
                   "equip_short_1": "Up to 6\"",
                   "equip_short_2": "+1/12",
                   "equip_long_1": "6\"-18\"",
                   "equip_long_2": "-1/12",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": []
               }
             ]
           },
           {
             "unit_id": 4,
             "unit_name": "Leader",
             "unit_stats": {
               "mv": "4",
               "mw": "15",
               "cc": "13",
               "st": "6",
               "def": "0",
               "ar": "22",
               "w": "2",
               "pw": "12",
               "ld": "14",
               "base": "30mm",
               "dp": 4,
               "sp": 3
             },
             "unit_abilities": [
               {
                 "ability_id": 7,
                 "ability_name": "Camouflage (X)",
                 "ability_desc": "Any Obstruction modifiers to the TN for a Shoot Action Test targeting this Unit are increased by +X.",
                 "ability_mod": "1"
               },
               {
                 "ability_id": 11,
                 "ability_name": "Determination",
                 "ability_desc": "This Unit may re-roll any TN (PW) or TN (LD) Tests Failures.",
                 "ability_mod": ""
               },
               {
                 "ability_id": 8,
                 "ability_name": "Command (X)",
                 "ability_desc": "If this Unit is neither Engaged nor has a Pinned Counter it may: -Spend an Action to remove a Reaction or Pinned Counter from a Friendly Unit within 12? and non-Blocked LOS -Once per Turn, spend an Action and perform a TN (LD) Test. On a Success, a FriendlyUnit of Unit Type X within 12? and non-Blocked LOS may perform one Action when this Unit completes its Activation.",
                 "ability_mod": "Venusian Rangers"
               }
             ],
             "unit_equipment": [
               {
                 "equip_id": 5,
                 "equip_name": "HG-14 Shotgun",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "-",
                   "equip_short_1": "Up to 6\"",
                   "equip_short_2": "+1/13x2",
                   "equip_long_1": "6\"-18\"",
                   "equip_long_2": "-2/11",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": []
               },
               {
                 "equip_id": 2,
                 "equip_name": "MP-105 Handgun",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "+0/12",
                   "equip_short_1": "Up to 6\"",
                   "equip_short_2": "+1/12",
                   "equip_long_1": "6\"-18\"",
                   "equip_long_2": "-1/12",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": []
               }
             ],
             "unit_required": {
               "unit_name": "Venusian Rangers",
               "unit_count": "1"
             }
           }
         ]
       },
       {
         "card_id": 2,
         "card_name": "Blitzers",
         "card_units": [
           {
             "unit_id": 5,
             "unit_name": "Trooper",
             "unit_stats": {
               "mv": "4",
               "mw": "13",
               "cc": "13",
               "st": "5",
               "def": "-1",
               "ar": "21",
               "w": "2",
               "pw": "11",
               "ld": "12",
               "base": "30mm",
               "dp": 4,
               "sp": 0
             },
             "unit_abilities": [
               {
                 "ability_id": 5,
                 "ability_name": "Blitz",
                 "ability_desc": "When this Unit completes a Shoot Action that resulted in an Armor Test Failure for at least one Enemy Unit, this Unit may immediately perform a free Move Action.",
                 "ability_mod": ""
               },
               {
                 "ability_id": 42,
                 "ability_name": "Trailblaze",
                 "ability_desc": "This Unit ignores the Rough Terrain Trait.",
                 "ability_mod": ""
               }
             ],
             "unit_equipment": [
               {
                 "equip_id": 4,
                 "equip_name": "MP-103 SMG",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "-",
                   "equip_short_1": "Up to 6\"",
                   "equip_short_2": "+0/12",
                   "equip_long_1": "6\"-18\"",
                   "equip_long_2": "-1/11",
                   "equip_crit_fail": "19"
                 },
                 "equip_abilities": [
                   {
                     "ability_id": 2,
                     "ability_name": "Burst (X)",
                     "ability_desc": "",
                     "ability_mod": "2"
                   }
                 ]
               },
               {
                 "equip_id": 2,
                 "equip_name": "MP-105 Handgun",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "+0/12",
                   "equip_short_1": "Up to 6\"",
                   "equip_short_2": "+1/12",
                   "equip_long_1": "6\"-18\"",
                   "equip_long_2": "-1/12",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": []
               }
             ]
           },
           {
             "unit_id": 6,
             "unit_name": "Support",
             "unit_stats": {
               "mv": "4",
               "mw": "13",
               "cc": "13",
               "st": "5",
               "def": "-1",
               "ar": "21",
               "w": "2",
               "pw": "11",
               "ld": "12",
               "base": "30mm",
               "dp": 4,
               "sp": -1
             },
             "unit_abilities": [
               {
                 "ability_id": 5,
                 "ability_name": "Blitz",
                 "ability_desc": "When this Unit completes a Shoot Action that resulted in an Armor Test Failure for at least one Enemy Unit, this Unit may immediately perform a free Move Action.",
                 "ability_mod": ""
               },
               {
                 "ability_id": 42,
                 "ability_name": "Trailblaze",
                 "ability_desc": "This Unit ignores the Rough Terrain Trait.",
                 "ability_mod": ""
               }
             ],
             "unit_equipment": [
               {
                 "equip_id": 9,
                 "equip_name": "Gehenna Puker",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "-",
                   "equip_short_1": "Up to 10\"",
                   "equip_short_2": "+3/13",
                   "equip_long_1": "-",
                   "equip_long_2": "-",
                   "equip_crit_fail": "17"
                 },
                 "equip_abilities": [
                   {
                     "ability_id": 4,
                     "ability_name": "Concussive",
                     "ability_desc": "",
                     "ability_mod": ""
                   },
                   {
                     "ability_id": 7,
                     "ability_name": "Projected",
                     "ability_desc": "",
                     "ability_mod": ""
                   }
                 ]
               },
               {
                 "equip_id": 2,
                 "equip_name": "MP-105 Handgun",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "+0/12",
                   "equip_short_1": "Up to 6\"",
                   "equip_short_2": "+1/12",
                   "equip_long_1": "6\"-18\"",
                   "equip_long_2": "-1/12",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": []
               }
             ]
           },
           {
             "unit_id": 7,
             "unit_name": "Specialist - Operator",
             "unit_stats": {
               "mv": "4",
               "mw": "13",
               "cc": "13",
               "st": "5",
               "def": "-1",
               "ar": "21",
               "w": "2",
               "pw": "11",
               "ld": "12",
               "base": "30mm",
               "dp": 4,
               "sp": 0
             },
             "unit_abilities": [
               {
                 "ability_id": 5,
                 "ability_name": "Blitz",
                 "ability_desc": "When this Unit completes a Shoot Action that resulted in an Armor Test Failure for at least one Enemy Unit, this Unit may immediately perform a free Move Action.",
                 "ability_mod": ""
               },
               {
                 "ability_id": 41,
                 "ability_name": "Tactical",
                 "ability_desc": "",
                 "ability_mod": ""
               },
               {
                 "ability_id": 42,
                 "ability_name": "Trailblaze",
                 "ability_desc": "This Unit ignores the Rough Terrain Trait.",
                 "ability_mod": ""
               }
             ],
             "unit_equipment": [
               {
                 "equip_id": 4,
                 "equip_name": "MP-103 SMG",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "-",
                   "equip_short_1": "Up to 6\"",
                   "equip_short_2": "+0/12",
                   "equip_long_1": "6\"-18\"",
                   "equip_long_2": "-1/11",
                   "equip_crit_fail": "19"
                 },
                 "equip_abilities": [
                   {
                     "ability_id": 2,
                     "ability_name": "Burst (X)",
                     "ability_desc": "",
                     "ability_mod": "2"
                   }
                 ]
               },
               {
                 "equip_id": 2,
                 "equip_name": "MP-105 Handgun",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "+0/12",
                   "equip_short_1": "Up to 6\"",
                   "equip_short_2": "+1/12",
                   "equip_long_1": "6\"-18\"",
                   "equip_long_2": "-1/12",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": []
               },
               {
                 "equip_id": 10,
                 "equip_name": "Frag Grenades",
                 "equip_group": "1",
                 "equip_stats": {
                   "equip_cc": "-",
                   "equip_short_1": "Up to ST\"",
                   "equip_short_2": "+0/11",
                   "equip_long_1": "ST\"-12\"",
                   "equip_long_2": "-3/11",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": [
                   {
                     "ability_id": 6,
                     "ability_name": "Explosive (X)",
                     "ability_desc": "",
                     "ability_mod": "2"
                   },
                   {
                     "ability_id": 13,
                     "ability_name": "Tactical Fire",
                     "ability_desc": "",
                     "ability_mod": ""
                   }
                 ]
               },
               {
                 "equip_id": 11,
                 "equip_name": "Hi-Ex Grenades",
                 "equip_group": "1",
                 "equip_stats": {
                   "equip_cc": "-",
                   "equip_short_1": "Up to ST\"",
                   "equip_short_2": "+0/14",
                   "equip_long_1": "ST\"-12\"",
                   "equip_long_2": "-3/14",
                   "equip_crit_fail": "19"
                 },
                 "equip_abilities": [
                   {
                     "ability_id": 6,
                     "ability_name": "Explosive (X)",
                     "ability_desc": "",
                     "ability_mod": "1"
                   },
                   {
                     "ability_id": 13,
                     "ability_name": "Tactical Fire",
                     "ability_desc": "",
                     "ability_mod": ""
                   }
                 ]
               },
               {
                 "equip_id": 12,
                 "equip_name": "Smoke Grenades",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "-",
                   "equip_short_1": "Up to ST\"",
                   "equip_short_2": "+0/-",
                   "equip_long_1": "ST\"-12\"",
                   "equip_long_2": "-3/-",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": [
                   {
                     "ability_id": 3,
                     "ability_name": "Cloud (X)",
                     "ability_desc": "",
                     "ability_mod": "2"
                   },
                   {
                     "ability_id": 11,
                     "ability_name": "Smoke",
                     "ability_desc": "",
                     "ability_mod": ""
                   },
                   {
                     "ability_id": 13,
                     "ability_name": "Tactical Fire",
                     "ability_desc": "",
                     "ability_mod": ""
                   }
                 ]
               }
             ],
             "unit_required": {
               "unit_name": "Blitzers",
               "unit_count": "1"
             }
           },
           {
             "unit_id": 8,
             "unit_name": "Leader",
             "unit_stats": {
               "mv": "4",
               "mw": "14",
               "cc": "14",
               "st": "5",
               "def": "-1",
               "ar": "21",
               "w": "2",
               "pw": "11",
               "ld": "14",
               "base": "30mm",
               "dp": 5,
               "sp": 2
             },
             "unit_abilities": [
               {
                 "ability_id": 5,
                 "ability_name": "Blitz",
                 "ability_desc": "When this Unit completes a Shoot Action that resulted in an Armor Test Failure for at least one Enemy Unit, this Unit may immediately perform a free Move Action.",
                 "ability_mod": ""
               },
               {
                 "ability_id": 42,
                 "ability_name": "Trailblaze",
                 "ability_desc": "This Unit ignores the Rough Terrain Trait.",
                 "ability_mod": ""
               },
               {
                 "ability_id": 8,
                 "ability_name": "Command (X)",
                 "ability_desc": "If this Unit is neither Engaged nor has a Pinned Counter it may: -Spend an Action to remove a Reaction or Pinned Counter from a Friendly Unit within 12? and non-Blocked LOS -Once per Turn, spend an Action and perform a TN (LD) Test. On a Success, a FriendlyUnit of Unit Type X within 12? and non-Blocked LOS may perform one Action when this Unit completes its Activation.",
                 "ability_mod": "Blitzers"
               }
             ],
             "unit_equipment": [
               {
                 "equip_id": 5,
                 "equip_name": "HG-14 Shotgun",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "-",
                   "equip_short_1": "Up to 6\"",
                   "equip_short_2": "+1/13x2",
                   "equip_long_1": "6\"-18\"",
                   "equip_long_2": "-2/11",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": []
               },
               {
                 "equip_id": 2,
                 "equip_name": "MP-105 Handgun",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "+0/12",
                   "equip_short_1": "Up to 6\"",
                   "equip_short_2": "+1/12",
                   "equip_long_1": "6\"-18\"",
                   "equip_long_2": "-1/12",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": []
               }
             ],
             "unit_required": {
               "unit_name": "Blitzers",
               "unit_count": "1"
             }
           }
         ]
       },
       {
         "card_id": 3,
         "card_name": "Etoiles Mortant",
         "card_units": [
           {
             "unit_id": 9,
             "unit_name": "Trooper",
             "unit_stats": {
               "mv": "5",
               "mw": "12",
               "cc": "15",
               "st": "6",
               "def": "-2",
               "ar": "20",
               "w": "2",
               "pw": "13",
               "ld": "13",
               "base": "30mm",
               "dp": 4,
               "sp": 0
             },
             "unit_abilities": [
               {
                 "ability_id": 4,
                 "ability_name": "Awareness",
                 "ability_desc": "While possessing no more than one Reaction Counter, this Unit may be assigned a Reaction Counter to perform a Reaction.",
                 "ability_mod": ""
               },
               {
                 "ability_id": 12,
                 "ability_name": "Dodge",
                 "ability_desc": "When this Unit performs a Dive for Cover Reaction, perform a Test with a TN equal to the lowest die result of the Enemy Unit?s Shoot Action. On a Success, this Unit does not receive a Reaction Counter.",
                 "ability_mod": ""
               },
               {
                 "ability_id": 17,
                 "ability_name": "Evasive (X)",
                 "ability_desc": "This Unit modifies the Rating of its DEF Characteristic by X when targeted by an Enemy Unit?s Strike Action. For example, a Unit with a DEF Rating of -1 and Duelist (-2) would have a DEF Rating of -3 when targeted by a Strike Action. Enemy Unit?s Shoot Action. For example, a Unit with a DEF Rating of 0 and Evasive (-2) would have a DEF Rating of -2 when targeted by a Shoot Action.",
                 "ability_mod": "-2"
               }
             ],
             "unit_equipment": [
               {
                 "equip_id": 3,
                 "equip_name": "Punisher Handgun",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "-2/12",
                   "equip_short_1": "Up to 6\"",
                   "equip_short_2": "+0/13",
                   "equip_long_1": "6\"-18\"",
                   "equip_long_2": "-2/12",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": []
               },
               {
                 "equip_id": 1,
                 "equip_name": "Punisher Shortsword",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "1/ST+7",
                   "equip_short_1": "-",
                   "equip_short_2": "-",
                   "equip_long_1": "-",
                   "equip_long_2": "-",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": [
                   {
                     "ability_id": 8,
                     "ability_name": "Reach (X)",
                     "ability_desc": "",
                     "ability_mod": "1"
                   },
                   {
                     "ability_id": 15,
                     "ability_name": "Vicious",
                     "ability_desc": "",
                     "ability_mod": ""
                   }
                 ]
               }
             ]
           },
           {
             "unit_id": 10,
             "unit_name": "Support",
             "unit_stats": {
               "mv": "5",
               "mw": "12",
               "cc": "15",
               "st": "6",
               "def": "-2",
               "ar": "20",
               "w": "2",
               "pw": "13",
               "ld": "13",
               "base": "30mm",
               "dp": 4,
               "sp": -2
             },
             "unit_abilities": [
               {
                 "ability_id": 4,
                 "ability_name": "Awareness",
                 "ability_desc": "While possessing no more than one Reaction Counter, this Unit may be assigned a Reaction Counter to perform a Reaction.",
                 "ability_mod": ""
               },
               {
                 "ability_id": 12,
                 "ability_name": "Dodge",
                 "ability_desc": "When this Unit performs a Dive for Cover Reaction, perform a Test with a TN equal to the lowest die result of the Enemy Unit?s Shoot Action. On a Success, this Unit does not receive a Reaction Counter.",
                 "ability_mod": ""
               },
               {
                 "ability_id": 17,
                 "ability_name": "Evasive (X)",
                 "ability_desc": "This Unit modifies the Rating of its DEF Characteristic by X when targeted by an Enemy Unit?s Strike Action. For example, a Unit with a DEF Rating of -1 and Duelist (-2) would have a DEF Rating of -3 when targeted by a Strike Action. Enemy Unit?s Shoot Action. For example, a Unit with a DEF Rating of 0 and Evasive (-2) would have a DEF Rating of -2 when targeted by a Shoot Action.",
                 "ability_mod": "-2"
               }
             ],
             "unit_equipment": [
               {
                 "equip_id": 7,
                 "equip_name": "PSG-99 Sniper Rifle",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "-",
                   "equip_short_1": "Up to 24\"",
                   "equip_short_2": "+1/14",
                   "equip_long_1": "24\"-48\"",
                   "equip_long_2": "+1/13",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": [
                   {
                     "ability_id": 1,
                     "ability_name": "Accurate (X)",
                     "ability_desc": "",
                     "ability_mod": "3"
                   }
                 ]
               },
               {
                 "equip_id": 1,
                 "equip_name": "Punisher Shortsword",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "1/ST+7",
                   "equip_short_1": "-",
                   "equip_short_2": "-",
                   "equip_long_1": "-",
                   "equip_long_2": "-",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": [
                   {
                     "ability_id": 8,
                     "ability_name": "Reach (X)",
                     "ability_desc": "",
                     "ability_mod": "1"
                   },
                   {
                     "ability_id": 15,
                     "ability_name": "Vicious",
                     "ability_desc": "",
                     "ability_mod": ""
                   }
                 ]
               }
             ]
           },
           {
             "unit_id": 11,
             "unit_name": "Leader",
             "unit_stats": {
               "mv": "5",
               "mw": "13",
               "cc": "16",
               "st": "6",
               "def": "-2",
               "ar": "20",
               "w": "2",
               "pw": "13",
               "ld": "15",
               "base": "30mm",
               "dp": 5,
               "sp": 2
             },
             "unit_abilities": [
               {
                 "ability_id": 4,
                 "ability_name": "Awareness",
                 "ability_desc": "While possessing no more than one Reaction Counter, this Unit may be assigned a Reaction Counter to perform a Reaction.",
                 "ability_mod": ""
               },
               {
                 "ability_id": 12,
                 "ability_name": "Dodge",
                 "ability_desc": "When this Unit performs a Dive for Cover Reaction, perform a Test with a TN equal to the lowest die result of the Enemy Unit?s Shoot Action. On a Success, this Unit does not receive a Reaction Counter.",
                 "ability_mod": ""
               },
               {
                 "ability_id": 17,
                 "ability_name": "Evasive (X)",
                 "ability_desc": "This Unit modifies the Rating of its DEF Characteristic by X when targeted by an Enemy Unit?s Strike Action. For example, a Unit with a DEF Rating of -1 and Duelist (-2) would have a DEF Rating of -3 when targeted by a Strike Action. Enemy Unit?s Shoot Action. For example, a Unit with a DEF Rating of 0 and Evasive (-2) would have a DEF Rating of -2 when targeted by a Shoot Action.",
                 "ability_mod": "-2"
               },
               {
                 "ability_id": 8,
                 "ability_name": "Command (X)",
                 "ability_desc": "If this Unit is neither Engaged nor has a Pinned Counter it may: -Spend an Action to remove a Reaction or Pinned Counter from a Friendly Unit within 12? and non-Blocked LOS -Once per Turn, spend an Action and perform a TN (LD) Test. On a Success, a FriendlyUnit of Unit Type X within 12? and non-Blocked LOS may perform one Action when this Unit completes its Activation.",
                 "ability_mod": "Etoiles Mortant"
               }
             ],
             "unit_equipment": [
               {
                 "equip_id": 3,
                 "equip_name": "Punisher Handgun",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "-2/12",
                   "equip_short_1": "Up to 6\"",
                   "equip_short_2": "+0/13",
                   "equip_long_1": "6\"-18\"",
                   "equip_long_2": "-2/12",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": []
               },
               {
                 "equip_id": 1,
                 "equip_name": "Punisher Shortsword",
                 "equip_group": "0",
                 "equip_stats": {
                   "equip_cc": "1/ST+7",
                   "equip_short_1": "-",
                   "equip_short_2": "-",
                   "equip_long_1": "-",
                   "equip_long_2": "-",
                   "equip_crit_fail": "20"
                 },
                 "equip_abilities": [
                   {
                     "ability_id": 8,
                     "ability_name": "Reach (X)",
                     "ability_desc": "",
                     "ability_mod": "1"
                   },
                   {
                     "ability_id": 15,
                     "ability_name": "Vicious",
                     "ability_desc": "",
                     "ability_mod": ""
                   }
                 ]
               }
             ],
             "unit_required": {
               "unit_name": "Etoiles Mortant",
               "unit_count": "1"
             }
           }
         ]
       }
    ]

    //setFactionUnitList(sampleJson[0]["faction_cards"]);
    return sampleJson; //["faction_cards"];
  }

  function Reinforcements() {
    // Review the logic on page 13
    // Reinforcement DP cannot be more than 10% of game size
    // Any numer
  }

  function SwitchFactionModal() {
    setFactionModal(!factionModal);
  }

  function GotoHome() {
    // Reset lists
    ResetList();
    setGuiState("faction");
  }

  function ResetList() {
    setFactionList({});
    setPlayerUnitList({});
    setSelectedFaction("");
  }

  function AddPlayerUnit( newCardId, newCardName, newUnit, newEquipOption ) {
    // Add Unit stats to Player List
    let cardId = newCardId;
    let cardName = newCardName;
    //console.log(newUnit);
    // Lookup existing records
    console.log([...playerUnitListState]);
    let i = 0;
    let tempArr = [...playerUnitListState];
    let tempStats = [];
    console.log(tempArr);
    //console.log(cardName)
    if(tempArr.findIndex(item=>item.card_name === cardName) > -1)
    {
      i = tempArr.findIndex(item=>item.card_name === cardName);
      tempStats = [...tempArr[i].unit_stats];
      console.log(tempStats);
      //console.log(tempArr[i])
    } else {
      // Setup new playerUnitList
      tempArr.push({ "card_id" : cardId, "card_name" : cardName, "unit_stats": []})
      i = tempArr.length-1;
      //console.log("New Card")
    }

    //tempStats.push(newUnit);

    tempArr[i].unit_stats = [...tempStats, {...newUnit}] //tempStats;
    setPlayerUnitList(tempArr);
    
    //console.log(playerUnitListState);
    //console.log(tempArr);
  }

  function RemovePlayerUnit(oldCardName, oldUnitId) {
    let tempArr = [...playerUnitListState];
    let i = tempArr.findIndex(item=>item.card_name === oldCardName);

    tempArr[i].unit_stats.splice(oldUnitId, 1);
    setPlayerUnitList(tempArr)
  }

  useEffect(() => {
    if(guiState == "build")
    {
      ValidateList();
    } else if(guiState == "db")
    {
      LoadFactionData();
    }
  }, [playerUnitListState]);

  function ValidateList() {
    let listErrors = [];

    let boolLeader = false;
    let calcDP = 0;
    let calcSP = 0;

    // Units with Troop costs
    let troopCount = []; // troopCount["Venusian Rangers"] = 1
    let requireSpecialCount = []; // requireCount["Venusian Rangers"] = 1
    let requireLeaderCount = [];
    let totalTroopCount = 0; // Calculate total number of Troopers
    let totalSpecialCount = 0; // Calculate total number of required units
    let eitherValidation = [];
    let anyCount = 0; // If a requirement has an Any value
    let anyCountOffset = 0; // Based on 

    // Setup Default Count settings
    factionUnitListState.forEach((card, index)=> {
      troopCount[card["card_name"]] = 0;
      requireSpecialCount[card["card_name"]] = 0;
      requireLeaderCount[card["card_name"]] = 0;
    })

    // Loop through Cards
    playerUnitListState.forEach((card,index)=>{
      //troopCount[card["card_name"]] = 0;
      //requireSpecialCount[card["card_name"]] = 0;
      //requireLeaderCount[card["card_name"]] = 0;

      // Loop through Units
      card["unit_stats"].forEach((unit, unit_index)=> {
        // Tally points
        calcDP += unit["unit_stats"]["dp"];
        calcSP += unit["unit_stats"]["sp"];
        // Check Leader
        if(unit["unit_name"] == "Leader")
        {
          boolLeader = true;
        }

        if(unit["unit_name"] == "Trooper") 
        {
          troopCount[card["card_name"]]++;
          totalTroopCount++;
        }

        if(unit["unit_required"])
        {
          // One Trooper can satisfy both Leader and Specialist requirement
          if(unit["unit_name"] == "Leader")
          {
            if(unit["unit_required"]["unit_name"].includes(",")) {
              // Special Case for Either/Or 
              if(eitherValidation[unit["unit_required"]["unit_name"]] != undefined)
                eitherValidation[unit["unit_required"]["unit_name"]] += unit["unit_required"]["unit_count"];
              else
                eitherValidation[unit["unit_required"]["unit_name"]] = unit["unit_required"]["unit_count"];
            } else if(unit["unit_required"]["unit_name"].toLowerCase().includes("any")) {
              // Special Case for Any
              anyCount += unit["unit_required"]["unit_count"];
            } else {
              requireLeaderCount[unit["unit_required"]["unit_name"]] += unit["unit_required"]["unit_count"];
              console.log("Require leader tally "+unit["unit_required"]["unit_name"]+ " -- "+ unit["unit_required"]["unit_count"])
              totalSpecialCount += unit["unit_required"]["unit_count"];
            }
          } else if(unit["unit_required"]["unit_name"].includes(",")) {
            // Special Case for Either/Or 
            if(eitherValidation[unit["unit_required"]["unit_name"]] != undefined)
                eitherValidation[unit["unit_required"]["unit_name"]] += unit["unit_required"]["unit_count"];
              else
                eitherValidation[unit["unit_required"]["unit_name"]] = unit["unit_required"]["unit_count"];
          } else if(unit["unit_required"]["unit_name"].toLowerCase().includes("any")) {
            // Special Case for Any
            anyCount += unit["unit_required"]["unit_count"];
          } else {
            requireSpecialCount[unit["unit_required"]["unit_name"]] += unit["unit_required"]["unit_count"];
            totalSpecialCount += unit["unit_required"]["unit_count"];
          }
        }
      })

    });

    // Update States
    setPointsCalc(calcDP)
    setSPCalc(calcSP)

    // Is Leader Present
    if(!boolLeader)
    {
      listErrors.push("Leader Unit Required")
    }

    // Is DP points greater than total
    if(calcDP > pointsTotal)
    {
      listErrors.push("Exceeded Total Points")
    }

    if(calcSP < 0)
    {
      listErrors.push("SP Requirement Not Met")
    }

    console.log(requireSpecialCount);
    Object.keys(requireSpecialCount).forEach((requireKey) => 
    {
      console.log("Leader count "+requireLeaderCount[requireKey]);
      if(requireLeaderCount[requireKey] > requireSpecialCount[requireKey])
      {
        // TODO: Bug here, Leader tally isn't coming up
        requireSpecialCount[requireKey] += requireLeaderCount[requireKey] - requireSpecialCount[requireKey];
        console.log(requireSpecialCount);
      }
      if(requireSpecialCount[requireKey] > troopCount[requireKey])
      {
        listErrors.push(requireKey + " - "+troopCount[requireKey]+" of "+requireSpecialCount[requireKey]+" Required");
        anyCountOffset += requireSpecialCount[requireKey] - troopCount[requireKey];
      }
    });

    // Either Troop requirement
    // If Either, loop through the keys and get the total counted for each key.  Combine the total and see if the difference in required count vs 
    Object.keys(eitherValidation).forEach((eitherKey) =>
    {
      // Split on the comma and strip 
      let eitherList = eitherKey.split(",");
      let eitherCount = eitherValidation[eitherKey];
      let eitherTroopCount = 0;
      let eitherErrorMsg = eitherKey.replace(",", " or");
      Object.keys(eitherList).forEach((eitherListKey) =>
      {
        if(requireSpecialCount[eitherList[eitherListKey].trim()] != undefined)
        {
          // Another special unit has a requirement
          eitherTroopCount += troopCount[eitherList[eitherListKey].trim()] - requireSpecialCount[eitherList[eitherListKey].trim()];
        } else if (troopCount[eitherList[eitherListKey].trim()] != undefined){
          // Existing Troop count without existing Special Unit requirement
          eitherTroopCount += troopCount[eitherList[eitherListKey].trim()];
        } else {
          //eitherTroopCount += 0;
          //console.log("troopCount-"+eitherList[eitherListKey].trim())
        }
      });
      console.log("Either Count: "+eitherCount+" / "+eitherTroopCount);
      if(eitherCount > eitherTroopCount)
      {
        let tempEitherDiff = eitherCount - eitherTroopCount;
        listErrors.push(tempEitherDiff + " of "+eitherErrorMsg+" Required");
      }
    });

    // "Any" Troop requirement
    if(anyCount > 0 && anyCount - totalTroopCount + totalSpecialCount - anyCountOffset > 0)
    {
      let tempDiff = anyCount - totalTroopCount + totalSpecialCount - anyCountOffset;
      listErrors.push("Additional "+tempDiff+" Trooper Unit Required");
    }
    if(listErrors.length == 0)
    {
      setListErrors([]);
      setListValid(true);
    } else {
      setListErrors(listErrors);
      setListValid(false);
    }
  }

  function PrintPage() {
      window.print();
  }

  function PrintPDF() {
   let domValid = document.getElementById("list-settings");
    let domPlayerList = document.getElementById("printListContainer");
    //html2canvas(domPlayerList)
    //  .then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    let uriValid;
    domValid.style.width = '640px';
    domPlayerList.style.width = '640px';
    domPlayerList.style.display = 'block';
    domPlayerList.style.paddingBottom = '32px';
    htmlToImage.toPng(domValid, {canvasWidth:640})
      .then(function (dataURL) {
         uriValid = dataURL;
      })
    htmlToImage.toPng(domPlayerList, {})
      .then(function (dataURL) {
         
        //const pdf = new jsPDF({unit:"px", format:[450, domPlayerList.offsetHeight*5/6]});
        //let domHeight = domPlayerList.getBoundingClientRect().height;
        //domHeight.height
        //let domHeight = getComputedStyle(domPlayerList).height;
        let domHeight = domPlayerList.offsetHeight*3/4;
        //console.log("Height: "+domHeight)
        
        domValid.style.width = 'auto';
        domPlayerList.style.display = 'none';
        domPlayerList.style.width = '100%';
        domPlayerList.style.paddingBottom = '0px';

        const pdf = new jsPDF({unit:"px", format:[450, domHeight]});
        pdf.addImage(uriValid, 'PNG', 0, 0);
        pdf.addImage(dataURL, 'PNG', 0, 20);
        pdf.save("Warzone List.pdf"); 
      });
      
     console.log("Print pdf")
  }

  ////////////////////////
  // Render //
  // States: faction, build, load, print
  ////////////////////////

  if(guiState == "faction")
  {
    return (
      <div className="App">
        <Navbar propGuiState={guiState} actionGotoHome={GotoHome} actionPrintPage={PrintPage} actionPrintPDF={PrintPDF} statePoints={pointsTotal} statePointChange={setPointsTotal}/>
        <header >
          
        </header>
        <div className="mx-auto px-1 bg-white">

          <div className="faction-container">
            <h2 className='font-bold text-center text-lg'>Select a Faction</h2>
            <div className='flex flex-row justify-center'>
              <FactionButton propID={2} propName={"Capitol"} propIcon={"icon-capitol"} actionSelectFaction={SelectFaction}/>
              <FactionButton propID={1} propName={"Bauhaus"} propIcon={"icon-bauhaus"} actionSelectFaction={SelectFaction}/>
              <FactionButton propID={4} propName={"Imperial"} propIcon={"icon-imperial"} actionSelectFaction={SelectFaction}/>
              <FactionButton propID={5} propName={"Mishima"} propIcon={"icon-mishima"} actionSelectFaction={SelectFaction}/>
            </div>
            <div className='flex flex-row justify-center'>
              <FactionButton propID={3} propName={"Cybertronic"} propIcon={"icon-cybertronic"} actionSelectFaction={SelectFaction}/>
              <FactionButton propID={7} propName={"Algeroth"} propIcon={"icon-algeroth"} actionSelectFaction={SelectFaction}/>
              <FactionButton propID={6} propName={"Brotherhood"} propIcon={"icon-brotherhood"} actionSelectFaction={SelectFaction}/>

            </div>
          </div>

        </div>
      </div>
    );
  }else if(guiState == "build")
  {
    return (
      <div className="App">
        <Navbar propGuiState={guiState} actionGotoHome={GotoHome} actionPrintPage={PrintPage} actionPrintPDF={PrintPDF} statePoints={pointsTotal} statePointChange={setPointsTotal}/>
        <header >
          
        </header>
        <div className='block md:hidden print:hidden'>
          <button className='bg-white hover:bg-yellow-500 text-black px-3 py-1 my-3 text-sm leading-5 rounded-r-full' onClick={()=> SwitchFactionModal()}><AiFillPlusCircle className='inline text-xl mr-2' />Faction Roster</button>
        </div>
        
        <div className="mx-auto px-1 bg-white text-sm">

        <ValidationBar 
          selectedFaction={selectedFaction} 
          pointsCalc={pointsCalc} 
          pointsTotal={pointsTotal} 
          spCalc={spCalc} 
          listValid={listValid} 
          listErrors={listErrors} 
        />

          <div className="builder-container flex flex-row md:min-w-[935px] print:hidden">
            <FactionListContainer 
              propFactionUnits={factionUnitListState} 
              actionAddUnit={AddPlayerUnit} 
              modalState={factionModal}
              modalStateEvent={setFactionModal}
            />
            <PlayerListContainer 
              propUnitList={playerUnitListState} 
              actionRemoveUnit={RemovePlayerUnit}
            />
            
          </div>
          <div>
            <PrintListContainer
                propUnitList={playerUnitListState}
              />
          </div>

        </div>
      </div>
    );
  } else if(guiState == "db")
  {
    return (
      <div className=' bg-slate-300'>
        {factionList.map((faction) => (
          <ol key={faction.faction_id}>
            <div>{faction.faction_name}</div>
          </ol>
        ))}
      </div>
    );
  }
}

export default App;
