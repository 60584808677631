import React from 'react'
import { PlayerUnitCard } from './PlayerUnitCard'

export const PlayerListContainer = ({ propUnitList, actionRemoveUnit }) => {

  let playerUnitList = []

  // Setup for multiple 
  propUnitList.forEach((item,index)=>{
    playerUnitList.push( <PlayerUnitCard propUnitStats={item} actionRemoveUnit={actionRemoveUnit}/>)
  });

  return (
    <div id='playerListContainer' className='w-[100%] md:w-[50%]' >
      <div className="list-container m-0 md:m-4 min-h-[300px]">
        {playerUnitList} 
      </div>
    </div>
  )
}
