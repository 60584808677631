import React from 'react'
import { UnitProfileCard } from './UnitProfileCard'
import {AiFillPlusCircle} from 'react-icons/ai';

export const FactionListContainer = ({ propFactionUnits, actionAddUnit, modalState, modalStateEvent}) => {

  let factionUnitList = [];

  // Setup for multiple 
  propFactionUnits.forEach((item,index)=>{
    factionUnitList.push( <UnitProfileCard propUnitStats={item} actionAddUnit={actionAddUnit} />)
  });

  function CloseFactionModal() {
    modalStateEvent(false);
  }

  function NormalView() {
    return  <div className='hidden md:block w-[50%] h-full overflow-auto '>
        <div className="list-container builder-unit-list m-4 min-h-[300px]">
          {factionUnitList}
        </div>
    </div>
  }

  function ModalView() {
    return  <div className='fixed left-0 top-0 w-[100%] h-full border-r-gray-900 bg-[#003] ease-in-out duration-300'>
      <div>
          <button className='bg-white hover:bg-yellow-500 text-black px-3 py-1 my-3 text-sm leading-5 rounded-r-full' onClick={()=> CloseFactionModal()}><AiFillPlusCircle className='inline text-xl mr-2' />Hide Roster</button>
          </div>
          <div className="list-container builder-unit-list m-4 min-h-[300px] h-5/6 overflow-auto">
            {factionUnitList}
          </div>
      </div>
  }

  return (
    modalState || window.innerWidth < '425px' ? ModalView() : NormalView()
  )
}
