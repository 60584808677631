import React, {useState} from 'react';
import {AiOutlineSetting, AiFillFilePdf, AiOutlineClose, AiOutlineMenu, AiFillPrinter, AiFillFile} from 'react-icons/ai';
// Assets
import logo from '../assets/WarzoneEternalLogo-FRTransparent.png';

export const Navbar = ({propGuiState, actionGotoHome, actionPrintPage, actionPrintPDF, statePoints, statePointChange}) => {
    const [nav, setNav] = useState(false);
    const [settings, setSettings] = useState(false);
    const [formData, setFormData] = useState({"list-points" : statePoints})

    const handleNav = () => {
        setNav(!nav);
    }

    const handleHome = () => {
        actionGotoHome();
    }

    const handleSetting = (event) => {
        event.preventDefault();
        setSettings(!settings);
        setFormData({"list-points" : statePoints});
    }

    const handleCloseSetting = () => {
        setSettings(false);
    }

    const handleSettingChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSettingSave = (event) => {
        event.preventDefault();
        statePointChange(formData["list-points"]);
        setSettings(!settings);
    }

    const handlePrint = () => {
        //actionPrintPDF();
    }

    return (
        <div className='text-white flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 border-yellow-500 border-double border-y-8 border-x-4 print:hidden'>
            <div className="logo">
                <a href="/"><img className="logo-img" src={logo} alt="Warzone Eternal"/></a>
            </div>
            <h1 className='w-full text-3xl font-bold text-yellow-500'><a href="/">List Builder</a></h1>
            <ul className='hidden md:flex'>
                <li className={propGuiState=="build" ? 'p-4' : 'hidden'} ><a href="/">New<AiFillFile /></a></li>
                <li className='p-4' onClick={handleSetting}>Settings<AiOutlineSetting /></li>
                <li className={propGuiState=="build" ? 'p-4' : 'hidden'} onClick={() => actionPrintPage()}>Print<AiFillPrinter /></li>
                <li className={propGuiState=="build" ? 'p-4' : 'hidden'} onClick={() => actionPrintPDF()}>PDF<AiFillFilePdf /></li>
            </ul>
            <div onClick={handleNav} className='block md:hidden'>
                {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} /> }
            </div>
            <div className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r-gray-900 bg-[#003] ease-in-out duration-300' : 'fixed left-[-100%]'}>
                <div className="logo">
                    <img className="logo-img" src={logo} alt="Warzone Eternal"/>
                </div>
                <h1 className='w-full text-3xl font-bold text-yellow-500 m-8'>List Builder</h1>
                <ul className='uppercase p-4'>
                    <li className='p-4 border-b border-gray-600'><a href="/">New</a></li>
                    <li className='p-4 border-b border-gray-600' onClick={handleSetting}>Settings</li>
                    <li className={propGuiState=="build" ? 'p-4 border-b border-gray-600' : 'hidden'} onClick={() => actionPrintPage()}>Print</li>
                    <li className={propGuiState=="build" ? 'p-4 border-b border-gray-600' : 'hidden'} onClick={() => actionPrintPDF()}>PDF</li>
                </ul>
            </div>
            <div className={settings ? 'fixed top-16 left-1/3 w-1/3 min-w-fit border-2 border-gray-900 bg-slate-100 ease-in-out duration-300 text-black' : 'fixed top-[-100%]'}>
                <div className='border-yellow-500 border-double border-y-4 border-x-8 p-2 bg-[#003] print:border-black'>
                    <h3 className='text-center text-white'>List Settings</h3>
                </div>
                <form onSubmit={handleSettingSave}>
                <div className='flex flex-row'>
                    <label for="list-points" className='flex-[0.5] m-2'>Total DP</label>
                    <input name="list-points" value={formData["list-points"]} onChange={handleSettingChange} className='flex-[0.5] m-2'></input>
                </div>
                <div className='flex flex-row'>
                    <button className='flex-[0.5] m-2 bg-[#003] text-white' type="submit" >Save</button>
                    <button className='flex-[0.5] m-2 bg-[#003] text-white' onClick={handleSetting}>Close</button>
                </div>
                </form>
            </div>
        </div>
    )
}

//export default Navbar;