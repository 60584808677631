import React, {useState} from 'react';
import { UnitProfileOption } from './UnitProfileOption';
import {AiFillCaretDown, AiFillCaretRight, AiOutlineArrowUp, AiOutlineArrowDown, AiOutlineDelete} from 'react-icons/ai';
import { PlayerUnitOptions } from './PlayerUnitOptions';

export const PlayerUnitCard = ({ propUnitStats, actionRemoveUnit }) => {

    // Load in Unit Stats
    // Load in Varient Stats
    // Load in Unit options with ability to add to Player List
    // Load in Equipment Stats

    // Load in props
    let cardName = propUnitStats["card_name"];
    let cardUnits = propUnitStats["unit_stats"]; // Array of units

    const [hideCard, setHideCard] = useState(true);

        const handleCard = () => {
            setHideCard(!hideCard);
        }

    let outputUnits = [];
    cardUnits.forEach((item,index)=>{
        outputUnits.push( <PlayerUnitOptions propUnitOptions={item} actionRemoveUnit={actionRemoveUnit} propCardName={cardName} propUnitIndex={index}/>)
    })
        
    if(cardUnits.length > 0)
    {
        return (
            <div className='w-[100%]'>
                <div className='flex flex-row border-yellow-500 border-double border-y-4 border-x-8 p-2 bg-[#003] print:border-black' onClick={handleCard}>
                <div className=' text-white'>{hideCard ? <AiFillCaretDown size={20} /> : <AiFillCaretRight size={20} /> }</div>
                <h3 className='basis-1/2 text-white pl-4 text-base print:text-black'>{cardName}</h3>
                
                </div>

            <div className={hideCard ? 'block mb-3 px-1 print:border-x-2 print:border-b-2 border-[#003]' : 'hidden print:block mb-3 px-1 print:border-x-2 print:border-b-2 border-[#003]'}>
                {outputUnits}
                </div>
            </div>
        )
    }
}
