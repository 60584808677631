import React from 'react'

const ValidationBar = ({selectedFaction, pointsCalc, pointsTotal, spCalc, listValid, listErrors}) => {


  return (
    <div className='max-w-[1240px] mx-auto'>
        <div id="list-settings" className='list-settings flex flex-row pt-2 pb-2 text-lg'>
            <div className='flex-1 text-center'>
                <h2 className='font-bold'>{selectedFaction.toUpperCase()}</h2>
            </div>
            <div className='flex-1 md:flex md:flex-row text-center '>
                <div className='pl-4 md:flex-[0.5] md:text-right'>DP: {pointsCalc} / {pointsTotal}</div>
                <div className='pl-4 md:flex-[0.5] md:text-left'>SP: {spCalc}</div>
            </div>
            <div className={listValid ? 'text-green-500 flex-1 font-bold text-center' : 'text-red-500 flex-1 font-bold text-center'}>{listValid ? 'Valid' : 'Not Valid'}</div>
        </div>
        <div className='list-error flex flex-row'>
            <div className='flex-1 text-center'>{
            listErrors.map(function(data, index) {
                return(<span key={index}>{data}<br /></span>)
            })
            }</div>
        </div>
  </div>
  )
}

export default ValidationBar