import React from 'react';
import {AiFillCaretDown} from 'react-icons/ai';
import { PrintUnitOptions } from './PrintUnitOptions';

export const PrintUnitCard = ({propUnitStats}) => {
  // Load in Unit Stats
    // Load in Varient Stats
    // Load in Unit options with ability to add to Player List
    // Load in Equipment Stats

    // Load in props
    let cardName = propUnitStats["card_name"];
    let cardUnits = propUnitStats["unit_stats"]; // Array of units

    let outputUnits = [];
    cardUnits.forEach((item,index)=>{
        outputUnits.push( <PrintUnitOptions propUnitOptions={item} propCardName={cardName} propUnitIndex={index}/>)
    })
        
    if(cardUnits.length > 0)
    {
        return (
            <div className='w-[100%]'>
                <div className='flex flex-row border-black border-double border-y-4 border-x-4 p-2' style={{background: '#cfcfcf'}} >
                <div className=' text-black'> <AiFillCaretDown size={20} /> </div>
                <h3 className='basis-1/2 pl-4 text-base text-black'>{cardName}</h3>
                
                </div>

            <div className='block mb-1 px-1 print:border-x-2 print:border-b-2 border-black'>
                {outputUnits}
                </div>
            </div>
        )
    }
}
